import "@fontsource/rubik/variable.css"; // This contains ALL variable axes. Font files are larger.

import * as ga from "../lib/ga";

import { WalletConnectContext, Web3AccountProvider } from "../context/user";

import { ChakraProvider } from "@chakra-ui/react";
import PlausibleProvider from "next-plausible";
import { Web3ReactProvider } from "../context/web3";
import { ServicePriceProvider } from "../context/servicePrice";
import theme from "../theme";

function MyApp({ Component, pageProps }) {
  return (
    <PlausibleProvider trackOutboundLinks domain="memecoingen.com">
      <WalletConnectContext>
        <ChakraProvider theme={theme}>
          <ServicePriceProvider>
            <Web3ReactProvider>
              <Web3AccountProvider>
                <Component />
              </Web3AccountProvider>
            </Web3ReactProvider>
          </ServicePriceProvider>
        </ChakraProvider>
      </WalletConnectContext>
    </PlausibleProvider>
  );
}

export default MyApp;
