import React, { createContext, useContext, useMemo, useState } from "react";

const Web3Context = createContext(null);

function useWeb3Provider() {
  const context = useContext(Web3Context);

  if (!context) {
    throw new Error(
      `useWeb3Provider must be used within a Web3Context Provider`
    );
  }

  return context;
}

function Web3ReactProvider(props) {
  const [web3Provider, setWeb3Provider] = useState();

  const value = useMemo(() => [web3Provider, setWeb3Provider], [web3Provider]);
  return <Web3Context.Provider value={value} {...props} />;
}

export { useWeb3Provider, Web3ReactProvider };
