import React, { useState, useContext, createContext } from "react";

// Create a context
const MyContext = createContext();

// Create a provider component
function ServicePriceProvider({ children }) {
  const [status, setStatus] = useState("free"); // Initial state

  // Function to toggle between 'free' and 'paid'
  const toggleStatus = (newStatus) => {
    setStatus(newStatus);
  };

  return (
    <MyContext.Provider value={{ status, toggleStatus }}>
      {children}
    </MyContext.Provider>
  );
}

// Create a custom hook for easy access to the context
function useServicePrice() {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyProvider");
  }
  return context;
}

export { ServicePriceProvider, useServicePrice };
